<template>
  <article class="cell b2b-card product-card-item product-card-item--has-header" qaid="plpProductCardItem">
    <div class="b2b-card__header product-card-item__header">
      <product-label-year
        :product="product"
        :product-url-customizer-open="productUrlCustomizerOpen"
        :qaid="`productCard${itemId}`"
      />

      <product-compare-button v-if="isCompareButtonVisible" small :product="product" />
    </div>
    <a :href="productUrl" @click="trackProductClick">
      <div class="relative">
        <pdl-link
          v-if="isCustomizeVisible"
          standalone
          :href="product.projectOneUrl"
          class="absolute top-0 left-0 product-tile-customize"
        >
          {{ $t('projectOne.button.customize') }}
        </pdl-link>
        <image-component
          :is-responsive="true"
          :is-carousel="false"
          :asset-id="currentImageUrl"
          :alt-text="displayName"
          :breakpoint-map="productCardBreakpointMap"
          aspect-ratio="4x3"
          transformation="pad"
        />
      </div>
      <div class="b2b-card__body product-card-item__body">
        <div v-show="shouldDisplaySwatches" class="product-card-item__swatches" qaid="productCardSwatches">
          <pdl-swatch-group
            class="justify-center my-2"
            :options="swatches"
            :radio-name="radioName"
            :initial-selected-option="swatches[0]"
            @color-changed="changeImage"
          />
        </div>
        <h3 class="b2b-card__title product-card-item__title" qaid="productCardProductName">
          {{ displayName }}
        </h3>
        <p v-if="isPriceVisible" class="product-card-item__price" qaid="productCardPrice">
          <template v-if="isProductReferences">
            <span v-if="price" id="priceRange">
              <span class="product-tile__saleprice" :class="{salePrice: price.displayPrice.isSale}">{{
                displayPrice
              }}</span>
              <span v-if="price.excludeTaxes && isConsumerFriendlyMode" id="excludeTaxText" class="exclude-tax">
                {{ $t('product.price.excludeTax') }}
              </span>
            </span>

            <span v-if="price && price.displayPrice.oldPrice" class="text-gray-60 line-through ml-1">
              {{ price.displayPrice.oldPrice }}
            </span>
          </template>
          <template v-else>
            <span class="product-card-item__price--sale">{{ price.sale }}</span>
            <span v-if="price.adv" class="product-card-item__price--adv">{{ price.adv }}</span>
          </template>
        </p>
      </div>
    </a>
  </article>
</template>

<script>
import productCard from '@/components/containers/plp/mixins/product-card';
import trackProductTile from '@/mixins/tracking/track-product-tile';
import ImageComponent from '@/components/ImageComponent.vue';
import ImageBreakpointMap from '@/constants/image-breakpoint-map';
import {mapState} from 'vuex';
import {PdlLink} from '@pedal/pdl-link';

const PRODUCT_CARD_IMAGE_WIDTH = 800;

export default {
  name: 'ProductCardItem',
  components: {
    PdlLink,
    ImageComponent,
  },
  mixins: [productCard, trackProductTile],
  computed: {
    ...mapState('plp', ['isColorSwatchAllowed']),
    shouldDisplaySwatches() {
      return this.swatches.length > 0 && this.isColorSwatchAllowed;
    },
    productCardBreakpointMap() {
      return Object.values(ImageBreakpointMap).reduce(
        (prev, breakpointKey) => ({...prev, [breakpointKey]: PRODUCT_CARD_IMAGE_WIDTH}),
        {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card-item {
  &.product-card-item--has-header {
    padding-top: 2rem;
  }

  &__header {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    border-bottom: none;
  }

  &__body {
    text-align: center;
  }

  &__swatches {
    display: flex;
    justify-content: center;
  }

  &__title {
    @apply font-medium;
  }

  &__price {
    @apply text-sm;
    @apply mt-0;
    @apply mb-0;

    &--sale {
      color: var(--gray-80);
    }

    &--adv {
      color: var(--gray-60);
      text-decoration: line-through;

      &::before {
        content: '|';
        display: inline-block;
        margin-left: 0.125rem;
        margin-right: 0.25rem;
        text-decoration: none;
      }
    }
  }
}
</style>

<style lang="scss">
.pdl-link.pdl-link--standalone.product-tile-customize {
  span {
    text-decoration: none;
    box-shadow: 0 1px 0 0 currentColor;
    line-height: 1em;
  }

  &:hover {
    span {
      box-shadow: 0 1px 0 0 transparent;
    }
  }
}
</style>
